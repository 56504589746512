import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LessonCard, LessonCardLoader } from '@pages/portal';
import { adminHomeworkStore } from '@stores';
import { approveAccessForRoles } from '@helpers';
import { DeleteModal, PortalPlug } from '@components';
import { GraduateCap } from 'lomonosov-icons';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { HomeworkTemplateType } from '@lomonosov/types';

export const HomeworkTemplateList = observer(() => {
	const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
	const [currentHomeworkId, setCurrentHomeworkId] = useState<string | null>(null);

	const handleCloseDeleteModal = () => setIsShowDeleteModal(false);

	if (adminHomeworkStore.isLoading) {
		return <div className='homework-template-bank__list'>
			<LessonCardLoader/>
			<LessonCardLoader/>
			<LessonCardLoader/>
			<LessonCardLoader/>
		</div>;
	}

	if (!adminHomeworkStore.templates?.length) return <PortalPlug>Задания не найдены</PortalPlug>;

	const openDeleteHomeworkModal = (homeworkId) => {
		setCurrentHomeworkId(homeworkId);
		setIsShowDeleteModal(true);
	};

	const deleteHomework = async () => {
		if (!currentHomeworkId) return;
		await adminHomeworkStore.deleteTemplate(currentHomeworkId);
		setIsShowDeleteModal(false);
	};

	return (
		<div className='homework-template-bank__list'>
			{adminHomeworkStore.templates.map(homework => (
				<LessonCard
					key={homework.id}
					title={homework.name}
					options={[
						{
							icon: <TaskOutlinedIcon />,
							title: typeName[homework.type]
						},
						{
							icon: <PersonIcon />,
							title: `${homework.owner?.firstName} ${homework.owner?.secondName}`
						},
						{
							icon: <GraduateCap />,
							title: homework.discipline.name
						}
					]}
					link={`/admin/homework/${homework.id}`}
					menuOptions={[
						{
							title: 'Удалить',
							onClick: openDeleteHomeworkModal.bind(this, homework.id),
							disabled: !approveAccessForRoles(['Управляющий', 'Отдел продаж'])
						}
					]}
				/>
			))}
			{isShowDeleteModal &&
				<DeleteModal
					open={isShowDeleteModal}
					handleClose={handleCloseDeleteModal}
					action={deleteHomework}
				/>
			}
		</div>
	);
});

const typeName: Record<HomeworkTemplateType, string> = {
	automatic: 'ДЗ с автопроверкой',
	manual: 'ДЗ по 2 части',
	probe: 'Пробник'
};
