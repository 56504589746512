import { makeAutoObservable, runInAction } from 'mobx';
import { IGetAdminSettingsResponse } from '@interfaces';

import { AdminSettingsService } from '@services';

class AdminSettingsStore {
	settings: IGetAdminSettingsResponse['settings'] = [];

	constructor () {
		makeAutoObservable(this);
	}

	async getSettings () {
		try {
			const { data } = await AdminSettingsService.getSettings();
			runInAction(() => {
				this.settings = data.settings;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateSettings (newSetting: IGetAdminSettingsResponse['settings'][0]) {
		try {
			const { data } = await AdminSettingsService.updateSettings(newSetting.key, newSetting);
			runInAction(() => {
				this.settings = this.settings.map(s => s.key === data.key ? data : s);
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminSettingsStore = new AdminSettingsStore();
