import { makeAutoObservable, runInAction } from 'mobx';
import {
	IGetAllUsersItemResponse,
	IGetUserPurchasesResponse,
	IGetUserMentorsResponse,
	IGetUserReferralsResponse,
	ICreateUserDto
} from '@lomonosov/types';
import { IUpdateReferral, IUser } from '@interfaces';

import { AdminUserService, UserCourseService } from '@services';
import { generalStore } from '@stores';
import { QueryParams } from '@helpers';

class AdminUserStore {
	users: IGetAllUsersItemResponse[] = [];
	purchases: IGetUserPurchasesResponse = [];
	coursesForMotivation = [];
	mentors: IGetUserMentorsResponse = [];
	referrals: IGetUserReferralsResponse = [];
	currentUser: IUser;
	totalCount: number = 0;
	addModal: boolean = false;
	isLoading: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	async getUsers (page: number = 0, pageSize: number = 100, search: string = '', withRoles = false) {
		try {
			const { data } = await AdminUserService.getUsers(page, pageSize, search, withRoles);
			runInAction(() => {
				this.users = data.users.map(user => ({ ...user, name: `${user.firstName} ${user.secondName}` }));
				this.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getUserToken (userId) {
		try	{
			const { data } = await AdminUserService.getUserToken(userId);
			localStorage.setItem(QueryParams.TOKEN, data);
			localStorage.removeItem(QueryParams.SSO_TOKEN);
			window.location.href = '/personal-info';
		} catch (e) {
			console.log(e);
		}
	}

	async getUserById (userId) {
		try {
			const { data } = await AdminUserService.getUserById(userId);
			runInAction(() => {
				this.currentUser = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createUser (newUser: ICreateUserDto) {
		try {
			const { data } = await AdminUserService.createUser(newUser);
			runInAction(() => {
				this.users = [...this.users, data];
				this.changeAddModalState();
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteUser (userId: string) {
		try {
			await AdminUserService.deleteUser(userId);
			runInAction(() => {
				this.users = this.users.filter(user => user.id !== userId);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getUserRoles (userId: string) {
		try {
			const { data } = await AdminUserService.getUserRoles(userId);
			this.currentUser.roles = data;
		} catch (e) {
			console.log(e);
		}
	}

	async updateUserRoles (userId: string, role) {
		try {
			const { data } = await AdminUserService.updateUserRoles(userId, role);
			runInAction(() => {
				this.currentUser.roles = data.roles;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getUserPurchases (userId: string) {
		try {
			const { data } = await AdminUserService.getUserPurchases(userId);
			runInAction(() => {
				this.purchases = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getMotivationCourses (userId: string) {
		try {
			const { data } = await AdminUserService.getMotivationCourses(userId);
			runInAction(() => {
				this.coursesForMotivation = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async createUserPurchase (purchase: any) {
		try {
			const { data } = await AdminUserService.createUserPurchase(purchase);
			runInAction(() => {
				this.purchases.push(data);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async deleteUserPurchase (purchaseId: string) {
		try {
			await AdminUserService.deleteUserPurchase(purchaseId);
			runInAction(() => {
				this.purchases = this.purchases.filter(purchase => purchase.id !== purchaseId);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getUserMentors (userId: string) {
		try {
			const { data } = await AdminUserService.getUserMentors(userId);
			runInAction(() => {
				this.mentors = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateUserMentors (userId: string, mentors: any) {
		try {
			const { data } = await AdminUserService.updateUserMentors(userId, mentors);
			runInAction(() => {
				this.mentors = data.mentors;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async getUserReferrals (userId: string) {
		try {
			const { data } = await AdminUserService.getUserReferrals(userId);
			runInAction(() => {
				this.referrals = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateUserReferrals ({ referralId, body }: IUpdateReferral) {
		try {
			const { data } = await AdminUserService.updateUserReferrals({ referralId, body });
			runInAction(() => {
				body.invitingReferralId
					? this.referrals.push(data)
					: this.referrals = this.referrals.filter(referral => referral.id !== referralId);
			});
		} catch (e) {
			console.log(e);
		}
	}

	async updateUser (newUser) {
		if (newUser.password === this.currentUser.password) delete newUser.password;
		try {
			const { data } = await AdminUserService.updateUser(newUser.id, newUser);
			runInAction(() => {
				const userIndex = this.users.findIndex(user => user.id === newUser.id);
				this.users[userIndex] = data;
				this.currentUser = data;
				this.users = [...this.users];
			});
			generalStore.changeAlertState('success', 'Данные сохранены');
		} catch (e) {
			console.log(e);
		}
	}

	async changeUserBanStatus (userId: string, banStatus: boolean) {
		try {
			const { data } = await AdminUserService.changeUserBanStatus(userId, banStatus);
			runInAction(() => {
				this.currentUser = data;
			});
		} catch (e) {
			console.log(e);
		}
	}

	async changeUserHP (hpId: string, healthCount: number) {
		try {
			await AdminUserService.changeUserHp(this.currentUser.id, hpId, healthCount);
		} catch (e) {
			console.log(e);
		}
	}

	async updateSettingCourse (courseId:string, settingId: string, settingCourse) {
		try {
			await UserCourseService.updateSettingCourse(settingId, settingCourse);
		} catch (e) {
			console.log(e);
		}
	}

	changeAddModalState () {
		this.addModal = !this.addModal;
	}

	changeIsLoading (value: boolean) {
		this.isLoading = value;
	}
}

export const adminUserStore = new AdminUserStore();
