import { IGetActionLogsResponse } from '@interfaces';
import adminLoggingService from '@services/admin-services/admin-logging-service';
import { makeAutoObservable, runInAction } from 'mobx';

class AdminLoggingStore {
	logs: IGetActionLogsResponse['data'] = [];
	totalCount: number = 0;

	constructor () {
		makeAutoObservable(this);
	}

	async getActionLogs (take: number = 10, skip: number = 0, search: string = '') {
		try {
			const { data } = await adminLoggingService.getActionLogs(take, skip, search);
			runInAction(() => {
				this.logs = data.data;
				this.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminLoggingStore = new AdminLoggingStore();
