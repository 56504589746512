import React, { useEffect } from 'react';
import { Table } from '@components';
import { observer } from 'mobx-react-lite';
import { adminSettingsStore } from '@stores';

export default observer(() => {
	useEffect(() => {
		if (!adminSettingsStore.settings.length) {
			adminSettingsStore.getSettings();
		}
	}, []);

	return (
		<div>
			{adminSettingsStore.settings.length !== 0 &&
				<Table
					columns={[
						{ field: 'key', name: 'Ключ', copy: true, editable: false },
						{ field: 'title', name: 'Заголовок', editable: false },
						{ field: 'value', name: 'Значение' },
						{ field: 'description', name: 'Описание', editable: false },
						{ field: 'type', name: 'Тип', editable: false }
					]}
					data={adminSettingsStore.settings}
					actionRow={{
						edit: (id, newState) => adminSettingsStore.updateSettings(newState)
					}}
				/>
			}
		</div>
	);
});
