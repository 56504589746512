import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon, Checkbox, Tooltip } from '@mui/material';
import { adminTeacherStore, adminUserStore, adminRoleStore, adminMentorsStore } from '@stores';
import './style.css';

export default observer(() => {
	useEffect(() => {
		adminRoleStore.getRoles();
		adminUserStore.getUserRoles(adminUserStore.currentUser.id);
	}, []);

	return (
		<div className='role-panel'>
			<List className='role-panel__section'>
				{adminRoleStore.roles.map(role =>
					<RoleItem key={role.id} role={role}/>
				)}
			</List>
		</div>
	);
});

const RoleItem = observer((props: { role }) => {
	const { role } = props;
	const [isAdded, setIsAdded] = useState<boolean>(false);

	useEffect(() => {
		setIsAdded(adminUserStore.currentUser.roles.some(r => r.id === role.id));
	}, []);

	const onClick = async () => {
		adminUserStore.changeIsLoading(true);
		const updatedRoles = isAdded
			? adminUserStore.currentUser.roles.filter(r => r.id !== role.id)
			: [...adminUserStore.currentUser.roles, role];
		await adminUserStore.updateUserRoles(adminUserStore.currentUser.id, { roles: updatedRoles })
			.then(() => setIsAdded(!isAdded));

		if (updatedRoles.length) {
			if (!adminUserStore.currentUser.mentor) {
				await adminMentorsStore.createMentor(adminUserStore.currentUser.id);
			}
		}

		if (['Преподаватель', 'Отдел продаж', 'Управляющий'].some(r => updatedRoles.map(r => r.name).includes(r))) {
			if (!adminUserStore.currentUser.teacher) {
				await adminTeacherStore.createTeacher(adminUserStore.currentUser.id);
			}
		} else if (adminUserStore.currentUser.teacher) {
			await adminTeacherStore.deleteTeacherRole(adminUserStore.currentUser.teacher.id);
		}
		adminUserStore.changeIsLoading(false);
	};

	return (
		<ListItem className='role-panel__button'>
			<Tooltip title={roleTooltips[role.name]}>
				<ListItemButton
					onClick={onClick}
					disabled={adminUserStore.isLoading}
				>
					<ListItemIcon>
						<Checkbox checked={isAdded}/>
					</ListItemIcon>
					<ListItemText>
						{role.name}
					</ListItemText>
				</ListItemButton>
			</Tooltip>
		</ListItem>
	);
});

const roleTooltips = {
	Ментор: <span>
		Доступно:<br/>
		-Проверка заданий<br/>
		-Проверка сочинений<br/>
		-Все пользователи(только стать ментором)
	</span>,
	'Ментор – ассистент': <span>
		Доступно:<br/>
		-Допуск уровня &#34;ментор&#34;<br/>
		-Банк дз(загружать и редактировать)
	</span>,
	'Старший ментор': <span>
		Доступно:<br/>
		-Допуск уровня &#34;ментор&#34;<br/>
		-Контроль менторов
	</span>,
	Преподаватель: <span>
		Доступно:<br/>
		-Допуск уровня &#34;Ментор – ассистент&#34; + &#34;Старший ментор&#34;<br/>
		-Курсы(запуск трансляций, добавление/удаление материалов к урокам)
	</span>,
	'Отдел продаж': <span>Полный допуск</span>,
	Управляющий: <span>Полный допуск</span>
};
