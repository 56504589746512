import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { IGetAdminSettingsResponse } from '@interfaces';

export default class {
	static async getSettings (): Promise<AxiosResponse<IGetAdminSettingsResponse>> {
		return $api.get<IGetAdminSettingsResponse>('/settings');
	}

	static async updateSettings (key: string, newSetting: IGetAdminSettingsResponse['settings'][0]): Promise<AxiosResponse<IGetAdminSettingsResponse['settings'][0]>> {
		return $api.put<IGetAdminSettingsResponse['settings'][0]>(`/settings/${key}/setValue`, newSetting);
	}
}
