import React, { useEffect } from 'react';
import { Chip } from '@mui/material';
import { Button, Table, Loader } from '@components';
import { observer } from 'mobx-react-lite';

import { adminWormStore } from '@stores';

import './style.css';

export default observer(() => {
	useEffect(() => {
		if (!adminWormStore.wormResults.length) {
			adminWormStore.getWormResults();
		}
	}, []);

	const startWorm = async () => {
		await adminWormStore.startWorm();
		await adminWormStore.getWormResults();
	};

	if (adminWormStore.isLoading) {
		return (
			<Loader/>
		);
	}

	return (
		<div>
			<div className='worm-results__button'>
				<Button type='main_green' onClick={startWorm}>
					Запустить червя
				</Button>
			</div>
			<Table
				columns={[
					{ field: 'sheetId', name: 'Идентификатор таблицы', copy: true },
					{ field: 'page', name: 'Название листа' },
					{ field: 'type', name: 'Тип', element: ({ type }) => <span>{type === 'manual' ? 'Ручной' : 'Автоматический'}</span>, type: 'element' },
					{ field: 'issued', name: 'Выдано' },
					{ field: 'extended', name: 'Продлено' },
					{
						field: 'error',
						name: 'Ошибка',
						type: 'element',
						element: ({ error }) => <Chip className='worm-results__chip' label={error ?? 'Отсутствует'} color={error ? 'error' : 'success'}/>
					}
				]}
				data={adminWormStore.wormResults.map(result => ({ ...result, ...result.result }))}
			/>
		</div>
	);
});
