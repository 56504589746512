import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppBar, Toolbar, Button } from '@mui/material';

import { approveAccessForRoles } from '@helpers';
import { TabPanel } from '@components';
import {
	AddCoursePanel,
	AddRolePanel,
	MentorsPanel,
	UserInfo,
	ReferralsPanel,
	MotivationPanel,
	SessionPanel
} from '../index';

import './style.css';

export default observer(() => {
	const [tabPanel, setTabPanel] = useState<string>(approveAccessForRoles(['Управляющий', 'Отдел продаж']) ? 'info' : 'mentors');

	return (
		<div className='users-detail-panel'>
			<AppBar sx={{ boxShadow: 'none', background: 'transparent' }} position='static'>
				<Toolbar>
					<Button
						disabled={!approveAccessForRoles(['Управляющий', 'Отдел продаж'])}
						className='users-detail-panel__menu-button'
						sx={{ color: tabPanel === 'info' ? '#FF3364' : '' }}
						onClick={() => setTabPanel('info')}
					>
						Общая информация
					</Button>
					<Button
						disabled={!approveAccessForRoles(['Управляющий', 'Отдел продаж'])}
						className='users-detail-panel__menu-button'
						sx={{ color: tabPanel === 'purchases' ? '#FF3364' : '' }}
						onClick={() => setTabPanel('purchases')}
					>
						Покупки
					</Button>
					<Button
						disabled={!approveAccessForRoles(['Управляющий'])}
						className='users-detail-panel__menu-button'
						sx={{ color: tabPanel === 'roles' ? '#FF3364' : '' }}
						onClick={() => setTabPanel('roles')}
					>
						Роли
					</Button>
					<Button
						className='users-detail-panel__menu-button'
						sx={{ color: tabPanel === 'mentors' ? '#FF3364' : '' }}
						onClick={() => setTabPanel('mentors')}
					>
						Менторы
					</Button>
					<Button
						disabled={!approveAccessForRoles(['Управляющий', 'Отдел продаж'])}
						className='users-detail-panel__menu-button'
						sx={{ color: tabPanel === 'referrals' ? '#FF3364' : '' }}
						onClick={() => setTabPanel('referrals')}
					>
						Рефералы
					</Button>
					<Button
						disabled={!approveAccessForRoles(['Управляющий', 'Отдел продаж'])}
						className='users-detail-panel__menu-button'
						sx={{ color: tabPanel === 'motivation' ? '#FF3364' : '' }}
						onClick={() => setTabPanel('motivation')}
					>
						Мотивационная система
					</Button>
					<Button
						disabled={!approveAccessForRoles(['Управляющий'])}
						className='users-detail-panel__menu-button'
						sx={{ color: tabPanel === 'session' ? '#FF3364' : '' }}
						onClick={() => setTabPanel('session')}
					>
						Сессии
					</Button>
				</Toolbar>
			</AppBar>
			<div>
				<TabPanel name='info' value={tabPanel}>
					<UserInfo/>
				</TabPanel>
				<TabPanel name='purchases' value={tabPanel}>
					<AddCoursePanel/>
				</TabPanel>
				<TabPanel name='roles' value={tabPanel}>
					<AddRolePanel/>
				</TabPanel>
				<TabPanel name='mentors' value={tabPanel}>
					<MentorsPanel/>
				</TabPanel>
				<TabPanel name='referrals' value={tabPanel}>
					<ReferralsPanel/>
				</TabPanel>
				<TabPanel name='motivation' value={tabPanel}>
					<MotivationPanel/>
				</TabPanel>
				<TabPanel name='session' value={tabPanel}>
					<SessionPanel/>
				</TabPanel>
			</div>
		</div>
	);
});
