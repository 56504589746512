import { userStore } from '@stores';

export const approveAccessForRoles = (roles: string[]) => {
	const roleValues = getRoleValues();

	return roles.some(role => roleValues[role]);
};

export const checkIsUserEmployee = () => {
	return !!userStore.user.roles.length;
};

export const blockAccessForRoles = (roles: string[]) => {
	const roleValues = getRoleValues();

	return !roles.some(role => roleValues[role]);
};

const getRoleValues = () => {
	return {
		Ментор: userStore.isMentor,
		'Ментор – ассистент': userStore.isMentorAssistant,
		'Старший ментор': userStore.isSeniorMentor,
		Преподаватель: userStore.isTeacher,
		'Отдел продаж': userStore.isMarketing,
		Управляющий: userStore.isAdmin
	};
};
