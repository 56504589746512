import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { styled, Button as MUIButton, IconButton } from '@material-ui/core';
import { DragAndDrop } from '@components';
import { constants } from '@helpers';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { fileStore } from '@stores';
import classNames from 'classnames';
import './style.css';

interface UploadPreviewProps {
	fileId?: string | null;
	onDelete?: () => void;
	onChangeImage?: (imageId: string) => void;
	isLocalDelete?: boolean;
	className?: string;
	url?: string;
	disabled?: boolean;
}

export const UploadPreview = observer((props: UploadPreviewProps) => {
	const { fileId, onDelete, onChangeImage, isLocalDelete, className, url, disabled = false } = props;
	const [isHoverButton, setHoverButton] = useState(false);
	const [imageId, setImageId] = useState<string | null>(fileId || null);

	useEffect(() => {
		if (isHoverButton && !imageId) {
			document.addEventListener('paste', onPasteImage);
			return () => document.removeEventListener('paste', onPasteImage);
		}
	}, [isHoverButton, imageId]);

	const savePreview = (data: { id: string }) => {
		setImageId(data.id);
		onChangeImage?.(data.id);
	};

	const onPasteImage = async (e) => {
		await fileStore.uploadFile(e.clipboardData, savePreview);
	};

	const onUploadFile = async (e) => {
		await fileStore.uploadFile(e, savePreview);
	};

	const onDeleteImage = async () => {
		if (isLocalDelete) {
			setImageId(null);
			onDelete?.();
		} else {
			await fileStore.deleteFile(imageId!, () => {
				setImageId(null);
				onDelete?.();
			});
		}
	};

	if (imageId || url) {
		return (
			<div
				className={classNames('upload-preview', 'upload-preview_active', className)}
				onMouseEnter={() => setHoverButton(true)}
				onMouseLeave={() => setHoverButton(false)}
			>
				<img
					className='upload-preview__image' alt='error'
					src={url || `${constants.URL}/files/${imageId}`}
				/>
				{isHoverButton &&
					<IconButton
						className='upload-preview__btn-delete'
						onClick={onDeleteImage}
						disabled={disabled}
					>
						<DeleteIcon fontSize='large'/>
					</IconButton>
				}
			</div>
		);
	}

	return (
		<MUIButton
			role={undefined}
			tabIndex={-1}
			component='label'
			color='default'
			className={classNames('upload-preview', className)}
			onMouseEnter={() => setHoverButton(true)}
			onMouseLeave={() => setHoverButton(false)}
			disabled={disabled}
		>
			<DragAndDrop
				className='upload-preview__content'
				handleDropFiles={onUploadFile}
			>
				<VisuallyHiddenInput type='file' accept='image/*' onChange={(e) => onUploadFile(e.target)}/>
					upload preview
				<CloudUploadIcon fontSize='large'/>
			</DragAndDrop>
		</MUIButton>
	);
});

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1
});
