import { makeAutoObservable, runInAction } from 'mobx';
import { AdminUserService } from '@services';

class AdminEmployeesStore {
	employees = [] as any;
	totalCount: number = 0;

	constructor () {
		makeAutoObservable(this);
	}

	async getAdmins (page: number = 0, pageSize: number = 5) {
		try {
			const { data } = await AdminUserService.getAdmins(page, pageSize);
			const formattedUsers = data.users.map(user => ({
				...user,
				roles: user?.roles?.map(role => role.name).join(' | ')
			}));

			runInAction(() => {
				this.employees = formattedUsers;
				this.totalCount = data.count;
			});
		} catch (e) {
			console.log(e);
		}
	}
}

export const adminEmployeesStore = new AdminEmployeesStore();
