import React, { useEffect, useState } from 'react';
import { JsonView, allExpanded, defaultStyles, darkStyles } from 'react-json-view-lite';
import { Table } from '@components';
import { Tooltip } from '@mui/material';
import { adminLoggingStore, generalStore } from '@stores';
import { observer } from 'mobx-react-lite';
import 'react-json-view-lite/dist/index.css';

export default observer(() => {
	const [currentBody, setCurrentBody] = useState<any>({});
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState('');
	const [timer, setTimer] = useState<any>();

	useEffect(() => {
		if (!adminLoggingStore.logs.length) {
			adminLoggingStore.getActionLogs();
		}
	}, []);

	const onSearchChange = (newSearch: string) => {
		setSearch(newSearch);
		clearTimeout(timer);
		setTimer(setTimeout(() =>
			adminLoggingStore.getActionLogs(rowsPerPage, page, newSearch),
		1000
		));
		setPage(0);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminLoggingStore.getActionLogs(rowsPerPage, newPage * rowsPerPage, search);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newRowsPerPage = parseInt(event.target.value, 10);
		setRowsPerPage(newRowsPerPage);
		adminLoggingStore.getActionLogs(newRowsPerPage, page * newRowsPerPage, search);
	};

	return (
		<Table
			columns={[
				{ field: 'id', name: 'id', editable: false, copy: true },
				{ field: 'actionName', name: 'Действие' },
				{ field: 'method', name: 'Метод' },
				{
					field: 'url',
					name: 'URL',
					type: 'element',
					element: (el) => <Tooltip title={el.url}>
						<span>{el.url?.split('/', 4).join('/')}</span>
					</Tooltip>
				},
				{ field: 'responseTime', name: 'Время выполнения' },
				{ field: 'statusCode', name: 'Статус' },
				{ field: 'owner', name: 'Автор', type: 'element', element: (el) => <span>{el.owner?.firstName} {el.owner?.secondName}</span> },
				{ field: 'createdAt', name: 'Время' }
			]}
			data={adminLoggingStore.logs}
			detailPanel={{
				Component: () => <div>
					<JsonView
						data={currentBody}
						shouldExpandNode={allExpanded}
						style={generalStore.theme === 'light' ? defaultStyles : darkStyles}
					/>
				</div>,
				action: async (id, data) => setCurrentBody(data.requestBody)
			}}
			pagination={{
				count: adminLoggingStore.totalCount,
				page: page,
				rowsPerPage: rowsPerPage,
				onPageChange: handleChangePage,
				onRowsPerPageChange: handleChangeRowsPerPage
			}}
			search={{
				prop: search,
				action: (str) => { onSearchChange(str); }
			}}
		/>
	);
});
