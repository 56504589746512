import { makeAutoObservable, runInAction } from 'mobx';
import { WormResultResponse } from '@interfaces';

import { AdminWormService } from '@services';

class AdminWormStore {
	wormResults: WormResultResponse['results'] = [];
	isLoading: boolean = false;

	constructor () {
		makeAutoObservable(this);
	}

	async getWormResults () {
		this.isLoading = true;
		try {
			const { data } = await AdminWormService.getWormResults();
			runInAction(() => {
				this.wormResults = data.results;
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}

	async startWorm () {
		this.isLoading = true;
		try {
			const { data } = await AdminWormService.startWorm();
			runInAction(() => {
				this.wormResults = [...this.wormResults, data];
			});
		} catch (e) {
			console.log(e);
		} finally {
			this.isLoading = false;
		}
	}
}

export const adminWormStore = new AdminWormStore();
