import React, { ReactNode, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Chip, IconButton, List, ListItem, Menu, MenuItem } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { constants } from '@helpers';
import classNames from 'classnames';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import './style.css';

export interface ILessonCardOption {
	title: string;
	icon: ReactNode;
}

type ILessonCardType = 'link' | 'file';

export interface ILessonMenuOption {
	title: string;
	onClick?: () => void;
	disabled?: boolean;
}

interface ILessonCardBaseProps {
	title: string;
	options: ILessonCardOption[];
	type?: ILessonCardType;
	className?: string;
	inlineTitle?: boolean;
	disabled?: boolean;
	badgeLabel?: string;
	isViewed?: boolean;
	menuOptions?: ILessonMenuOption[];
}

interface ILessonCardLinkProps extends ILessonCardBaseProps {
	link: string;
}

interface ILessonCardFileProps extends ILessonCardBaseProps {
	fileId: string;
}

type ILessonCardProps = XOR<ILessonCardLinkProps, ILessonCardFileProps>;

export const LessonCard = (props: ILessonCardProps) => {
	const { title, options, type = 'link', className, inlineTitle, disabled, badgeLabel, isViewed, menuOptions } = props;
	const [menuOpen, setMenuOpen] = useState<HTMLButtonElement | null>(null);
	const handleClose = () => setMenuOpen(null);

	const handleOpenMenuItem = (e: React.MouseEvent<HTMLButtonElement>): void => {
		setMenuOpen(e.currentTarget);
	};

	const button = useMemo(() => {
		if (type === 'file') {
			return (
				<Button
					href={`${constants.FILE_URL}/${props.fileId}`}
					target='_blank'
					rel='noreferrer noopener'
					className='lesson-card__button'
					variant='contained'
					endIcon={<ArrowDownwardIcon/>}
					disabled={disabled}
				>
					скачать
				</Button>
			);
		}

		if (disabled) {
			return (
				<Button
					className='lesson-card__button'
					variant='contained'
					endIcon={<ArrowForwardOutlinedIcon/>}
					disabled={disabled}
				>
					перейти
				</Button>
			);
		}

		return (
			<Link to={props!.link || '#'} className='lesson-card__button'>
				<Button
					className='lesson-card__button'
					variant='contained'
					endIcon={<ArrowForwardOutlinedIcon/>}
					disabled={disabled}
				>
					перейти
				</Button>
			</Link>
		);
	}, [type]);

	const cardMods: Record<string, boolean> = {
		isBigCard: options.length > 2,
		isInlineTitle: !!inlineTitle,
		isBadge: !!badgeLabel
	};

	return (
		<Card variant='outlined' className={classNames('lesson-card', className, cardMods)}>
			{badgeLabel && <Chip label={badgeLabel} className='lesson-card__badge' />}
			{!badgeLabel && isViewed !== undefined &&
				<div className={classNames(
					'lesson-card__is-viewed-dot theme-block-background-inverted',
					{ 'lesson-card__is-viewed-dot_viewed': isViewed, 'lesson-card__is-viewed-dot_unviewed': !isViewed })}
				/>
			}
			<p className='lesson-card__title'>
				{title}
			</p>
			<div className='lesson-card__container'>
				<List className='lesson-card__list'>
					{options.map((option) =>
						<ListItem key={option.title} disablePadding className='lesson-card__list-item'>
							{option.icon}
							<span>
								{option.title}
							</span>
						</ListItem>
					)}
				</List>
				<div className='lesson-card__buttons'>
					{!!menuOptions?.length &&
						<IconButton className='courses-card__icon-button' onClick={handleOpenMenuItem}>
							<MoreHorizIcon fontSize='large' />
						</IconButton>
					}
					{button}
				</div>
			</div>
			{!!menuOptions?.length &&
			<Menu className='courses-card__menu' anchorEl={menuOpen} keepMounted open={Boolean(menuOpen)} onClose={handleClose}>
				{menuOptions.map((option, index) =>
					<MenuItem key={index} onClick={() => { option?.onClick?.(); handleClose(); }} disabled={option.disabled}>
						{option.title}
					</MenuItem>
				)}
			</Menu>
			}
		</Card>
	);
};
