export enum WormResultType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
}

export interface WormResultResponse {
	results: Array<{
		id: string;
		createdAt: Date;
		updatedAt: Date;
		deletedAt: Date | null;
		// Идентификатор таблицы
		sheetId: string;
		// Название листа ('ТАРИФЫ' | 'DEL')
		page: string;
		// Время вызова запуска скрипта червя
		call: Date;
		// ['automatic', 'manual']
		type: WormResultType
		result: {
			issued: number;
			extended: number;
			error: string | null;
		};
	}>;
	count: number;
}
