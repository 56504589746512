import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate } from 'react-router-dom';
import { checkIsUserEmployee } from '@helpers';

import { userStore } from '@stores';

import '../admin-layout/style.css';

const mentorRoutes = [
	'/admin/all-users',
	'/admin/homework-answers',
	'/admin/analytics-students',
	'/admin/check-essays',
	'/admin/my-students'
];

const mentorAssistantRoutes = [
	...mentorRoutes,
	'/admin/homework-bank',
	'/admin/homework',
	'/admin/essays'
];

const seniorMentorRoutes = [
	...mentorRoutes,
	'/admin/control-mentors',
	'/admin/homework-answer'
];

const teacherRoutes = [
	...mentorRoutes,
	'/admin/homework-bank',
	'/admin/homework',
	'/admin/control-mentors',
	'/admin/courses',
	'/admin/course'
];

export default observer(() => {
	const navigate = useNavigate();

	const checkAccess = () => {
		if (!checkIsUserEmployee()) {
			navigate('/');
			return;
		}

		if (userStore.isMarketing || userStore.isAdmin) {
			return;
		}

		const availableRoutes: string[] = [];
		const currentLocation = window.location.pathname;

		if (userStore.isMentor) {
			availableRoutes.push(...mentorRoutes);
		}
		if (userStore.isMentorAssistant) {
			availableRoutes.push(...mentorAssistantRoutes);
		}
		if (userStore.isSeniorMentor) {
			availableRoutes.push(...seniorMentorRoutes);
		}
		if (userStore.isTeacher) {
			availableRoutes.push(...teacherRoutes);
		}

		if (!availableRoutes.some(route => currentLocation.includes(route))) {
			navigate('/admin/homework-answers');
		}
	};

	useEffect(() => {
		checkAccess();
	}, []);

	return (
		<Outlet />
	);
});
