import { AxiosResponse } from 'axios';
import { $api } from '@helpers';
import { WormResultResponse } from '@interfaces';

export default class {
	static async getWormResults (): Promise<AxiosResponse<WormResultResponse>> {
		return $api.get<WormResultResponse>('/worm-results');
	}

	static async startWorm (): Promise<AxiosResponse<WormResultResponse['results'][0]>> {
		return $api.get<WormResultResponse['results'][0]>('/spreadsheets/sync');
	}
}
