import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Pagination } from '@mui/material';
import dayjs from 'dayjs';
import { adminCoursesStore, adminHomeworkAnswersStore, userStore } from '@stores';
import { DeleteModal, PortalPlug } from '@components';
import { LessonCard, LessonCardLoader } from '@pages/portal';
import { HomeworkAnswersFilteringMenu } from './homework-answers-blocks';
import { HomeworkAnswerStatus } from '@lomonosov/types';

import { BookIcon, GraduateCap } from 'lomonosov-icons';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import './style.css';

export default observer(() => {
	useEffect(() => {
		if (!adminHomeworkAnswersStore.homeworkAnswers?.length) {
			adminHomeworkAnswersStore.changeFilterField(userStore.user?.mentor?.id, 'mentor');
			adminHomeworkAnswersStore.changeFilterField(HomeworkAnswerStatus.VERIFICATION, 'status');
			adminHomeworkAnswersStore.getAllHomeworkAnswers(8, 0);
		}
		if (adminCoursesStore.allCourses?.length === 0) {
			adminCoursesStore.getAllCourses(10, undefined, true, false);
		}
		if (!adminHomeworkAnswersStore.directions?.length || !adminHomeworkAnswersStore.disciplines?.length) {
			adminHomeworkAnswersStore.getFilters();
		}
	}, []);

	return (
		<div className='homework-answers'>
			<HomeworkAnswersFilteringMenu/>
			<AnswersList/>
			<hr/>
			<Pagination
				count={adminHomeworkAnswersStore.pagination.pages}
				page={adminHomeworkAnswersStore.pagination.currentPage}
				onChange={(e, page) =>
					adminHomeworkAnswersStore.changePage(page, 8)
				}
				defaultPage={1} size='large' showFirstButton showLastButton
				classes={{ ul: 'homework-answers__pagination' }}
			/>
		</div>
	);
});

export const AnswersList = observer(() => {
	const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
	const [currentHomeworkAnswerId, setCurrentHomeworkAnswerId] = useState<string | null>(null);

	const handleCloseDeleteModal = () => setIsShowDeleteModal(false);

	const openDeleteHomeworkModal = (homeworkId) => {
		setCurrentHomeworkAnswerId(homeworkId);
		setIsShowDeleteModal(true);
	};

	const deleteAnswer = () => {
		if (!currentHomeworkAnswerId) return;
		adminHomeworkAnswersStore.deleteHomeworkAnswerById(currentHomeworkAnswerId);
		setIsShowDeleteModal(false);
	};

	if (adminHomeworkAnswersStore.loader || adminHomeworkAnswersStore.isLoadingFilters) {
		return (
			<div className='homework-answers__list'>
				{[...new Array(6)].map((_, index) => <LessonCardLoader key={index}/>)}
			</div>
		);
	}

	if (!adminHomeworkAnswersStore.homeworkAnswers?.length) {
		return <PortalPlug>Заданий на проверку пока что нет</PortalPlug>;
	}

	return (
		<div className='homework-answers__list'>
			{adminHomeworkAnswersStore.homeworkAnswers.map((answer) =>
				<LessonCard
					key={answer.id}
					title={answer.homework.template.name}
					options={[
						{
							title: answer.homework.direction.name,
							icon: <BookIcon />
						},
						{
							title: answer.homework.discipline.name,
							icon: <GraduateCap />
						},
						{
							title: `от ${answer.user.firstName} ${answer.user.secondName}`,
							icon: <PermIdentityOutlinedIcon />
						},
						{
							title: dayjs(answer.createdAt).tz('GMT').format('DD.MM.YYYY HH:mm') + ' мск',
							icon: <CalendarMonthOutlinedIcon />
						}
					]}
					type='link'
					link={`/admin/homework-answer/${answer.id}`}
					menuOptions={[
						{
							title: 'Удалить',
							onClick: () => openDeleteHomeworkModal(answer.id)
						}
					]}
				/>
			)}
			<DeleteModal
				open={isShowDeleteModal}
				action={deleteAnswer}
				handleClose={handleCloseDeleteModal}
			>
				При удалении ответов пользователя будут удалены все ответы и вложения.
				После удаления, ученику нужно будет по новой проходить задание.
			</DeleteModal>
		</div>
	);
});
