import io from 'socket.io-client';
import { action, makeAutoObservable } from 'mobx';
import { userCourseStore, userStore, generalStore } from '@stores';
import { checkIsUserEmployee } from '@helpers';

class LessonChatStore {
	loader: boolean = true;
	messages: any = [];
	newMessage: string = '';
	mutedUntil: any = null;
	chatOff: boolean = false;
	chatInputText: string = 'Введите сообщение';
	inputBlocked: boolean = false;
	socket: any;

	constructor () {
		makeAutoObservable(this);
	}

	socketConnect () {
		this.socket = io('https://chat.lomonosov.tech', { transports: ['websocket'], upgrade: false });
	}

	connectLessonChat () {
		this.socket.emit('connect-chat', {
			user: {
				id: userStore.user.id,
				photo: userStore.user.photo,
				firstName: userStore.user.firstName,
				secondName: userStore.user.secondName,
				isMentor: checkIsUserEmployee()
			},
			chat: { id: userCourseStore.currentLesson.chatId }
		});
	}

	newMessageListener () {
		this.socket.on('new-messages', action((messages) => {
			this.messages = this.messages.concat(messages);
		}));
	}

	bannedUsersListener () {
		this.socket.on('user-muted', ({ id, until }) => {
			this.messages = this.messages.map(message =>
				message.user.id === id ? { ...message, user: { ...message.user, muteUntil: true } } : message
			);
			if (id === userStore.user.id) {
				this.mutedUntil = until;
				this.isDisabled();
			}
		});
	}

	unbannedUserListener () {
		this.socket.on('user-unmute', (id) => {
			if (id === userStore.user.id) {
				this.mutedUntil = null;
				this.chatInputText = 'Введите сообщение';
				this.inputBlocked = false;
			}
		});
	}

	offChatListener () {
		this.socket.on('chat-expire', isOff => {
			if (isOff) {
				this.chatOff = true;
			}
			this.isDisabled();
		});
	}

	deleteMessageListener () {
		this.socket.on('message-deleted', deleteMessageId => {
			this.messages = this.messages.filter(message => message.id !== deleteMessageId);
		});
	}

	sendMessage () {
		if (this.newMessage.replace(/^\s+|\s+$/g, '') === '') {
			generalStore.changeAlertState('warning', 'Нельзя отправить пустое сообщение');
		} else {
			this.socket.emit('send-message', {
				chat: { id: userCourseStore.currentLesson.chatId },
				message: {
					text: this.newMessage,
					user: {
						id: userStore.user.id,
						photo: userStore.user.photo,
						firstName: userStore.user.firstName,
						secondName: userStore.user.secondName,
						isMentor: checkIsUserEmployee()
					}
				}
			});
			this.newMessage = '';
		}
	}

	muteUser (userId) {
		this.socket.emit('mute-user', { chat: { id: userCourseStore.currentLesson.chatId }, user: { id: userId } });
	}

	deleteMessage (messageId) {
		this.socket.emit('delete-message', { chat: { id: userCourseStore.currentLesson.chatId }, message: { id: messageId } });
	}

	isDisabled = () => {
		if (this.chatOff) {
			this.chatInputText = 'Чат отключен';
			this.inputBlocked = true;
		} else if (this.mutedUntil && new Date() <= new Date(lessonChatStore.mutedUntil)) {
			this.chatInputText = `Вы забанены до ${new Date(this.mutedUntil).toLocaleTimeString().substr(0, 5)}`;
			this.inputBlocked = true;
		} else {
			this.chatInputText = 'Введите сообщение';
			this.inputBlocked = false;
		}
	};

	connectAll () {
		this.loader = true;
		this.connectLessonChat();
		this.newMessageListener();
		this.bannedUsersListener();
		this.deleteMessageListener();
		this.unbannedUserListener();
		this.offChatListener();
		this.loader = false;
	}

	changeNewMessage (value: string) {
		if (this.newMessage.length < 300) {
			this.newMessage = value;
		}
	}

	disconnect () {
		this.messages = [];
		this.socket.disconnect();
	}
}

export const lessonChatStore = new LessonChatStore();
