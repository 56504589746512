import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import Skeleton from '@material-ui/lab/Skeleton';
import { getTimeWithTZ } from '@helpers/functions/get-time-with-tz';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import './style.css';

dayjs.extend(weekOfYear);

interface ICalendar {
  now: Date
  start: Date
  end: Date
	schedule: any
	weekNumber: number
  isLoad: Boolean,
	dayOffset: number,
}

interface ICalendarWeek {
	name: string;
	date: Date,
	dayKey: number
}

const monthsNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
const hours = [...Array(24)].map((_, i) => i.toString());

const checkDate = (a: Date, b: Date) => {
	return a.toLocaleDateString() === b.toLocaleDateString();
};

const getDay = (date) => {
	const currentDayIndex = new Date(date).getDay();
	return currentDayIndex === 0 ? 6 : currentDayIndex - 1;
};

const skeleton = <Skeleton variant='rect' className='table-body__block_skeleton' />;

export default (props: ICalendar) => {
	const {
		now,
		start,
		end,
		schedule,
		weekNumber,
		isLoad,
		dayOffset
	} = props;

	const [daysArray, setDaysArray] = useState([] as ICalendarWeek[]);
	const anchorNowRedline = useRef<HTMLDivElement>(null);
	const anchorNow = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const defaultDayNames = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
		const week: ICalendarWeek[] = [];

		for (let date = new Date(start); new Date(date) <= new Date(end); date.setDate(date.getDate() + 1)) {
			week.push({
				name: defaultDayNames[getDay(date)],
				date: new Date(date),
				dayKey: getDay(date)
			});
		}

		const slicedWeek = week.slice().splice(0, dayOffset);
		setDaysArray(slicedWeek);
	}, [start, end]);

	useEffect(() => {
		if (anchorNow.current) {
			anchorNow.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		}
	}, []);

	useEffect(() => {
		if (anchorNowRedline.current) {
			const parentHeight = anchorNowRedline.current.parentElement?.clientHeight || 50;
			const parentWidth = anchorNowRedline.current.parentElement?.clientWidth || 50;
			const offset = (parentHeight / 60) * getTimeWithTZ().getMinutes();
			anchorNowRedline.current.style.top = `${offset}px`;
			anchorNowRedline.current.style.width = `${parentWidth}px`;
		}
	}, [isLoad]);

	return (
		<div className='class-calendar-desktop'>
			<table className='class-calendar-desktop__table'>
				<thead>
					<tr className='table-header theme-table-header theme-block-background'>
						<td className='table-header__block theme-table-header' />
						{daysArray.map(day => (
							<td
								key={`header-${day.dayKey}`}
								className={`table-header__block theme-table-header ${checkDate(day.date, now) ? 'table-header__block_active' : ''}`}>
								<div>
									{day.name}
								</div>
								<div>
									{day.date.getDate()} {monthsNames[day.date.getMonth()]}
								</div>
							</td>
						))}
					</tr>
				</thead>
				<tbody>
					{hours.map(hour => (
						<tr key={`table-body-${hour}`} className='table-body'>
							<td className='table-body__block theme-table-body table-body__time'>{hour}:00</td>
							{getTimeWithTZ().getHours().toString() === hour && (<div className='now' ref={anchorNow}></div>)}
							{daysArray.map(day => {
								return (
									<td key={`schedule-${day.dayKey}-${hour}`} className={`table-body__block theme-table-body ${checkDate(day.date, now) ? 'theme-table-active' : ''}`}>
										{(checkDate(day.date, now) && getTimeWithTZ().getHours().toString() === hour) && (<div id='now-anchor-redline' ref={anchorNowRedline} />)}
										{
											isLoad
												? (
													dayOffset === 7
														? schedule?.[weekNumber]?.[day?.dayKey || 0]?.[hour]
														: Object.entries(schedule).map(([_, v]) => v?.[day?.dayKey || 0]?.[hour])
												)
												: skeleton
										}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
