import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';

import { LessonCard, LessonCardLoader } from '@pages/portal';
import { BookIcon, GraduateCap, MailInbox } from 'lomonosov-icons';
import { DeleteModal, PortalPlug } from '@components';
import {
	adminCategoryStore,
	adminCoursesStore,
	adminDirectionStore,
	adminDisciplineStore,
	adminSectionsStore
} from '@stores';
import { CoursesFilteringMenu } from './courses-bank-blocks';
import { approveAccessForRoles, constants, images } from '@helpers';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import AddIcon from '@mui/icons-material/Add';

import './style.css';

export default observer(() => {
	useEffect(() => {
		adminCoursesStore.changeIsArchive(true);
		(async () => {
			if (!adminCoursesStore.courses.length) {
				await adminCoursesStore.getCourses();
				if (!adminDirectionStore.directions?.length) {
					adminDirectionStore.getDirections();
				}
				if (!adminCategoryStore.categories?.length) {
					adminCategoryStore.getCategories();
				}
				if (!adminDisciplineStore.disciplines?.length) {
					adminDisciplineStore.getDisciplines();
				}
				adminCoursesStore.changeSearch('');
			}
		})();
	}, []);

	const resetData = () => {
		adminCoursesStore.resetCurrentCourse();
		adminSectionsStore.resetSections();
	};

	const handleChangeCoursePage = () => {
		adminCoursesStore.changeIsArchive(!adminCoursesStore.isArchive);
		adminCoursesStore.getCourses();
	};

	return (
		<div className='courses-bank'>
			<CoursesFilteringMenu/>
			<div className='courses-bank__buttons'>
				{approveAccessForRoles(['Управляющий', 'Отдел продаж']) &&
					<Link to='/admin/course' className='courses-bank__button'>
						<Button
							startIcon={<AddIcon color='success'/>}
							onClick={() => resetData()}
							variant='outlined'
							color='success'
						>
							Создать новый курс
						</Button>
					</Link>
				}
				<div className='courses-bank__button'>
					<Button
						color='primary'
						variant='outlined'
						onClick={handleChangeCoursePage}
						startIcon={<MailInbox/>}
					>
						{adminCoursesStore.isArchive ? 'Архивные курсы' : 'Активные курсы'}
					</Button>
				</div>
			</div>
			<div className='courses-bank__list'>
				{adminCoursesStore.isLoading
					? <CoursesLoader/>
					: adminCoursesStore.courses.length !== 0
						? adminCoursesStore.courses.map(course =>
							<div key={course.id} className='courses-bank__list-item'>
								<img
									src={course.preview[0]?.id ? `${constants.FILE_URL}/${course.preview[0]?.id}` : images.defaultCourse}
									alt='course image'
									className='courses-bank__image'
									loading='lazy'
								/>
								<LessonCard
									className='courses-bank__lesson'
									title={course.name}
									link={`/admin/course/${course.id}`}
									options={[
										{ icon: <BookIcon/>, title: course.direction.name },
										{ icon: <GraduateCap/>, title: course.discipline.name },
										{ icon: <LibraryBooksOutlinedIcon/>, title: course.category.name }
									]}
									menuOptions={[
										{ title: 'Удалить', onClick: () => adminCoursesStore.changeDeletableCourseId(course.id), disabled: !approveAccessForRoles(['Управляющий', 'Отдел продаж']) }
									]}
								/>
							</div>
						)
						: <PortalPlug/>
				}
			</div>
			<hr/>
			<Pagination
				count={adminCoursesStore.pagination.pages}
				page={adminCoursesStore.pagination.currentPage}
				onChange={(e, page) => adminCoursesStore.changePage(page, 6)}
				defaultPage={1} size='large' showFirstButton showLastButton
				classes={{ ul: 'homework-template-bank__paginator' }}
			/>
			<DeleteModal
				open={!!adminCoursesStore.deletableCourseId}
				handleClose={() => adminCoursesStore.changeDeletableCourseId('')}
				action={() => adminCoursesStore.deleteCourse()}
			/>
		</div>
	);
});

const CoursesLoader = () => (
	<>
		<LessonCardLoader/>
		<LessonCardLoader/>
		<LessonCardLoader/>
		<LessonCardLoader/>
		<LessonCardLoader/>
		<LessonCardLoader/>
	</>
);
