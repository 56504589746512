import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from '@components';

import { adminUserStore } from '@stores';

import './style.css';

export default observer(() => {
	useEffect(() => {

	}, []);

	const sessionColumns = [
		{ field: 'id', name: 'ID', copy: true, editable: false },
		{ field: 'ip', name: 'IP', width: 120 },
		{ field: 'userAgent', width: 600, name: 'User Agent' },
		{ field: 'authorizedAt', name: 'Последний вход' }
	];

	return (
		<div className='session-panel'>
			<div className='session-panel__courses'>
				<Table
					title='Сессии'
					columns={sessionColumns}
					data={adminUserStore.currentUser.localSessions}
				/>
			</div>
		</div>
	);
});
