import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, FormControl, FormLabel, FormGroup, FormControlLabel } from '@mui/material';

import { adminUserStore, userStore } from '@stores';
import { Table, CustomModal, DeleteModal } from '@components';
import { UsersDetailPanel, AddUserModal } from './all-users-blocks';

import './style.css';
import { approveAccessForRoles } from '@helpers';

export default observer(() => {
	const [timer, setTimer] = useState<any>();
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState('');
	const [deletableUserId, setDeletableUserId] = useState<string>('');
	const [withRoles, setWithRoles] = useState<boolean>(false);

	const onSearchChange = (newSearch: string) => {
		setSearch(newSearch);
		clearTimeout(timer);
		setTimer(setTimeout(() =>
			adminUserStore.getUsers(page, rowsPerPage, newSearch, withRoles),
		1000
		));
		setPage(0);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		adminUserStore.getUsers(newPage, rowsPerPage, search, withRoles);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	useEffect(() => {
		handleChangePage(undefined, 0);
	}, [rowsPerPage]);

	const onOpenPanel = async (id) => {
		await adminUserStore.getUserById(id);
	};

	const deleteUser = () => {
		adminUserStore.deleteUser(deletableUserId).finally(() => setDeletableUserId(''));
	};

	const onChangeSwitch = () => {
		setWithRoles(!withRoles);
		adminUserStore.getUsers(page, rowsPerPage, search, !withRoles);
	};

	const columnsAdmin: any = [
		{ name: 'ID', field: 'shortId', copy: true },
		{ name: 'VK', field: 'vkId' },
		{ name: 'ФИО', field: 'name' },
		{ name: 'Email', field: 'email' },
		{ name: 'Телефон', field: 'phone' }
	];

	return (
		<>
			{approveAccessForRoles(['Управляющий', 'Отдел продаж']) &&
				<FormControl className='all-users__switch'>
					<FormLabel component='legend'>Фильтры</FormLabel>
					<FormGroup>
						<FormControlLabel
							label='Сотрудники'
							control={<Switch onChange={onChangeSwitch} value={withRoles}/>}
						/>
					</FormGroup>
				</FormControl>
			}
			<Table
				columns={columnsAdmin}
				data={adminUserStore.users}
				styleOption={{
					container: 'all-users__table-container'
				}}
				detailPanel={{
					Component: UsersDetailPanel,
					action: (id) => onOpenPanel(id),
					autoClose: true
				}}
				pagination={{
					count: adminUserStore.totalCount,
					page: page,
					rowsPerPage: rowsPerPage,
					onPageChange: handleChangePage,
					onRowsPerPageChange: handleChangeRowsPerPage
				}}
				search={{
					prop: search,
					action: (str) => { onSearchChange(str); }
				}}
				actionHeader={userStore.isAdmin
					? {
						add: () => adminUserStore.changeAddModalState()
					}
					: {}
				}
				actionRow={userStore.isAdmin
					? {
						delete: (id) => setDeletableUserId(id)
					}
					: {}
				}
			/>
			<CustomModal
				open={adminUserStore.addModal}
				handleClose={() => adminUserStore.changeAddModalState()}
				viewClose='cross'
				heightModal='auto'
			>
				<AddUserModal/>
			</CustomModal>
			<DeleteModal
				deleteName='Пользователя'
				open={!!deletableUserId}
				handleClose={() => setDeletableUserId('')}
				action={deleteUser}
			/>
		</>
	);
});
